<template>
  <div class="pt-30">
    <div class="content-box bg-white box-shadow mb-30">
      <div class="tabs">
        <div
          class="tab px-25 pt-20 active"
        >
          <!-- :class="{ active: coin == 'USDT' }"
          @click="onTab('USDT')" -->
          {{ $t("new_list.h") }}
        </div>
      </div>
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="font-14 color-gray px-20 py-15"
      >
        <el-col :span="4">{{ $t("home.pair") }}</el-col>
        <el-col :span="6">{{ $t("new_list.span2") }}</el-col>
        <el-col :span="6">{{ $t("new_list.span3") }}</el-col>
        <el-col class="text-right" :span="6">{{ $t("home.deal") }}</el-col>
      </el-row>
      <el-row
        v-for="(item, index) in market"
        v-loading="loading"
        :key="index"
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="market-body px-20 py-15 border-top"
      >
        <el-col :span="1">
          <div
            v-if="item.logo"
            class="icon icon-style-yuan icon-stars"
            :style="{ backgroundImage: 'url(' + item.logo + ')' }"
          />
          <div
            v-else
            class="icon icon-style-yuan icon-stars"
            :class="'icon-coin-' + item.pair.toLowerCase()"
          />
        </el-col>
        <el-col :span="3"
          ><span
            class="is-link color-p-h"
            @click="$nav.push('/issue/' + item.pair + '/' + item.coin + '/' +item.id)"
            >{{ item.pair.toUpperCase() }} / {{ item.coin.toUpperCase() }}</span
          ></el-col
        >
        <el-col :span="6">{{ item.issue_price }}</el-col>
        <el-col :span="6">{{ item.issue_date }}</el-col>
        <el-col class="deal text-right" :span="6">
          <el-button
            type="primary"
            size="small"
            plain
            class="bg-t"
            icon="el-icon-s-data"
            @click="$nav.push('/issue/' + item.pair + '/' + item.coin + '/' +item.id)"
            >{{ $t("home.trade") }}</el-button
          >
        </el-col>
      </el-row>
      <!-- <div
        v-if="market.length < 1"
        v-loading="true"
        element-loading-background="#00000000"
        class="p-50"
      /> -->
      <div class="pagination-box" v-if="market.length > 0">
        <el-pagination
          layout="prev, pager, next"
          background
          :total="total"
          :page-size="pageSize"
          :current-page="page"
          @current-change="pageFn"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";

export default {
  data() {
    return {
      // banner: this.$t("home.banner"),
      coinLogo,
      // coin: this.$store.state.marketCoin,
      noticeList: [],
      marketOld: null,
      market: [],
      showUp: false,
      showDown: false,
      loading: false,
      timer: null,
      dataVersion: 0,

      //新增
      marketLength: 0,
      socket1: null,

      total:null,
      page:1,
      pageSize:10,

      coin:'USDT',
    };
  },
  watch: {
    // coin: function () {
    //   this.market = [];
    //   if (["BTC", "ETH", "USDT"].indexOf(this.coin) < 0) return;
    //   this.$store.commit("marketCoin", this.coin);
    //   this.tabChange(true);
    // },
  },
  created: function () {
    // this.fatchNotice();
    this.tabChange();
    // this.timer = window.setInterval(() => {
    //   if (document.hidden) return;
    //   if (["BTC", "ETH", "USDT"].indexOf(this.coin) < 0) return;
    //   this.tabChange(false);
    // }, 2000);
  },
  beforeDestroy: function () {
    window.clearInterval(this.timer);
  },
  destroyed: function () {
    // this.socket1.close(); //离开路由之后断开websocket连接
  },
  methods: {
    // fatchNotice: async function () {
    //   const { data } = await this.$request.post("v1/notice");
    //   this.noticeList = data;
    // },
    onTab: function (active) {
      // this.coin = active;
    },

    tabChange: function () {
      this.loading = true;
      this.$requestNotState
        // .post("v1/symbol/getList", { coin: this.coin.toLowerCase() })
        .post("v1/issue_symbol/getList", { page:this.page,pageSize:this.pageSize })
        .then(({ data }) => {
          this.loading = false;
          this.market = data.list;
          this.total = data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    pageFn(e){
      this.page = e
      this.tabChange()
    }

    // onClick: function (item) {
    //   this.$nav.push("/trade/index/" + item.pair + "/" + item.coin);
    // },
  },
};
</script>
<style scoped>
.index-top {
  /* background-image: url(~@/assets/index/top-bg.png); */
  height: 300px;
  background-repeat: no-repeat;
  background-size: auto 460px;
  background-position: center;
}
.icon-style-yuan {
  border-radius: 50%;
}
</style>
<style lang="scss" scoped>
.notice-bar {
  .link {
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tabs {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $bgColor;
  .tab {
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .active {
    color: $--color-primary;
    border-bottom: 2px solid $--color-primary;
  }
}
.market-body {
  .change {
    font-size: 14px !important;
    width: 80px;
    text-align: center !important;
  }
}
.services {
  .item {
    img {
      width: 100px;
      height: 100px;
    }
    p {
      font-size: 14px;
    }
  }
}
.tabs .active{
    border-bottom: 0;
}
.pagination-box{
  text-align: center;
  padding: 20px 0;
  box-sizing: border-box;
}
</style>